export default `
query getCategoryContentData($filters: CategoryFilterInput) {
  categoryListTopImage: categoryList(filters: $filters) {
      uid
      display_mode
      landing_page
      cms_block {
        identifier
        content
      }
    }
  categoryListTopMeta: categories(filters: $filters) {
      items {
        meta_title
        meta_description
        meta_keywords
        name
      }
    }
  categoryList: categoryList(filters: $filters) {   
    include_in_menu     
    is_anchor     
    level      
    name      
    position    
    product_count      
    uid       
    url_path     
    url_suffix  
    image  
    description
    breadcrumbs {
      category_id
      category_level
      category_name
      category_uid
      category_url_key
      category_url_path
    }
    children {
      id
      uid
      level
      name
      path
      url_path
      url_key
      url_suffix  
      image
      include_in_menu
    }
  }     
  }
`;
