
































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useFetch,
  useRouter,
} from '@nuxtjs/composition-api';
import { SfBadge, SfButton, SfHeader, SfHeading } from '@storefront-ui/vue';

import SvgImage from '~/components/General/SvgImage.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import { useUiHelpers, useUiState } from '~/composables';
import { useUser } from '~/modules/account/composables/useUser';
import { useSidebarLinkGroups } from '~/modules/account/pages/MyAccount/useSidebarLinkGroups';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useCart } from '~/modules/checkout/composables/useCart';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';

import { useTopBar } from './TopBar/useTopBar';

export default defineComponent({
  components: {
    SfHeading,
    SfHeader,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
  },
  setup() {
    const router = useRouter();
    const { app, localePath } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, user } = useUser();
    const isShowUserHover = ref<boolean>(false);
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount, load: loadWishiList } =
      useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const { logoutUser } = useSidebarLinkGroups();
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const accountIcon = computed(() =>
      isAuthenticated.value ? 'user_acount' : 'user_acount'
    );
    const categoryTree = ref<CategoryTree[]>([]);
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'account-my-overview' }));
      } else {
        await router.push(localePath('/user/signin'));
      }
    };

    const handleRoute = async (pathStr) => {
      await router.push(localePath(pathStr));
    };

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });
      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu
      );
      if (categoryTree?.value?.length) {
        // add Brand
        categoryTree.value.push({
          children: [],
          level: 2,
          name: app.i18n.t('Brand'),
          url_path: 'brand',
          url_suffix: '',
        } as CategoryTree);
      }
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
      await loadWishiList();
    });

    const toggleSearchSidebar = (newVal) => {
      if (newVal === false && isSearchOpen.value === false) return;
      isSearchOpen.value = !isSearchOpen.value;
    };

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      hasCurrencyToSelect,
      hasStoresToSelect,
      goToCart,
      user,
      toggleSearchSidebar,
      isShowUserHover,
      handleRoute,
      logoutUser,
    };
  },
  head() {
    console.log('currentLocale', this.$i18n.locale);
    const currentLocale = this.$i18n.locale;
    const gbScript = [
      {
        id: 'ze-snippet',
        hid: 'ze-snippet',
        src: 'https://static.zdassets.com/ekr/snippet.js?key=92797b58-e4d5-42b4-8ea2-e1b13132b1fc',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'awin-track',
        id: 'awin-track',
        src: 'https://www.dwin1.com/5350.js',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'uk-kelkoo-tracking',
        id: 'uk-kelkoo-tracking',
        src: 'https://s.kk-resources.com/leadtag.js',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        id: 'UK-Baidu-Statistics',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement('script');
            hm.src = "https://hm.baidu.com/hm.js?a26516ae6e50505a6a08aa3b19d30b14";
            var s = document.getElementsByTagName('script')[0]; 
            s.parentNode.insertBefore(hm, s);
          })(); `,
      },
    ];
    const usScript = [
      {
        id: 'ze-snippet',
        hid: 'ze-snippet',
        src: 'https://static.zdassets.com/ekr/snippet.js?key=92797b58-e4d5-42b4-8ea2-e1b13132b1fc',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        id: 'US-Baidu-Statistics',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var hmt = hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?4642875e00813ca0cb35226ab637238b";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();`,
      },
      {
        id: 'US-Pinterest-tag',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          <!-- Pinterest Tag -->
            !function(e){if(!window.pintrk){window.pintrk = function () {
              window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
                n=window.pintrk;n.queue=[],n.version="3.0";var
                t=document.createElement("script");t.async=!0,t.src=e;var
                r=document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
              pintrk('load', '2613925467137', {em: '<user_email_address>'});
              pintrk('page');
            var img = document.createElement("img");
            img.src = "https://ct.pinterest.com/v3/?event=init&tid=2613925467137&pd[em]=<hashed_email_address>&noscript=1";
            img.setAttribute("height", "1");
            img.setAttribute("width", "1");
            img.setAttribute("alt", "");
            img.style.display = "none";
            document.addEventListener("DOMContentLoaded", function() {
             document.body.appendChild(img);
            });
            <!-- end Pinterest Tag -->`,
      },
    ];
    const cnScript = [
      {
        id: 'Rakten-Marketing-Tracking',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          (function (url) {
              /*Tracking Bootstrap
              Set Up DataLayer objects/properties here*/
              if(!window.DataLayer){
                  window.DataLayer = {};
              }
              if(!DataLayer.events){
                  DataLayer.events = {};
              }
              DataLayer.events.SiteSection = "1";
                          
              var loc, ct = document.createElement("script"); 
              ct.type = "text/javascript"; 
              ct.async = true;
              ct.src = url;
              loc = document.getElementsByTagName('script')[0];
              loc.parentNode.insertBefore(ct, loc);
          }(document.location.protocol + "//intljs.rmtag.com/114906.ct.js"));
        `,
      },
      {
        id: 'CN-Baidu-Statistics',
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.async = true;
            hm.src = "https://hm.baidu.com/hm.js?57dfcfdbea5be460f02efc02cc5ef9d8";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();`,
      },
    ];
    const scripts = {
      gb: gbScript,
      us: usScript,
      cn: cnScript,
    };
    const script = scripts[currentLocale] || [];
    if (process.client) {
      script.push({
        hid: 'Google-Tag-Manager',
        id: 'Google-Tag-Manager',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PSXRM6K9');
         document.addEventListener("DOMContentLoaded", function() {
            var iframe = document.createElement("iframe");
            iframe.src="https://www.googletagmanager.com/ns.html?id=GTM-PSXRM6K9";
            iframe.width = "0";
            iframe.height = "0";
            iframe.display = "none";
            iframe.style.visibility = "hidden";
            document.body.appendChild(iframe);
         });
        `,
      });
    }
    const enMeta = [
      {
        name: 'google-site-verification',
        content: '45B8X3QXx__9LzOm4szVMBO-b97h_WFLziKQ0UpAXmA',
      },
    ];
    const metas = {
      gb: enMeta,
      us: enMeta,
      cn: [],
    };
    const meta = metas[currentLocale] || [];
    return {
      script,
      meta,
    };
  },
});
